<template>
  <div class="operation-log">
    <app-list :opt="opt" @get="onGet"></app-list>
  </div>
</template>
<script>
let validAccount = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      let reg = /^[A-z0-9]{6,16}$/;
      if (!reg.test(value)) {
        callback(new Error("操作人账号格式错误"));
      } else {
        callback();
      }
    }
  };
  export default {
    data() {
      let _this = this;
      return {
        form: {
          date: ''
        },
        opt: {
          title: "操作日志",
          search: [{
            key: "date",
            label: "操作时间",
            type: "time-range",
            defaultValue: ["00:00:00", "23:59:59"]
          },
            {
              key: "moduleName",
              label: "模块",
            },
            {
              key: "operatorAccount",
              label: "操作人账号",
              maxlength: 16,
              rules: [{
                validator: validAccount,
                trigger: ['blur']
              }]
            },
          ],
          columns: [{
              label: "操作时间",
              key: "operateTime"
            },
            {
              label: "操作功能",
              key: "operateName"
            },
            // {
            //   label: "操作明细",
            //   key: "operateDetail",
            //   opt: {
            //     isUserPopover: true
            //   }
            // },
            {
              label: "模块",
              key: "moduleName"
            },
            {
              label: "操作人账号",
              key: "operatorAccount"
            },
            {
              label: "操作IP",
              key: "operatorIp"
            },
            {
              label: "操作人角色",
              key: "operatorRole"
            },
            {
              label: "操作人姓名",
              key: "operatorUser"
            },
            {
              label: "操作结果",
              key: "stateStr"
            },
          ]
        }
      };
    },
    created() {
      console.log("operation-log created!");
    },
    activated() {
      this.getMenuName(this.$route.path)
    },
    methods: {
      onRefresh() {
        console.log("refresh");
      },
      onSubmit() {
        console.log(this.form);
        for (let i = 0; i < this.buts.length; i++) {
          let item = this.buts[i];
          item.type = "primary ";
        }
        this.onGet();
      },
      onGet(opt) {
        var dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          startTime: opt.searchForm['date'] ? this.format(opt.searchForm['date'][0]) : null,
          endTime: opt.searchForm['date'] ? this.format(opt.searchForm['date'][1]) : null,
          ...opt.searchForm
          
        };
        this.post("user-service/operateLog/page/query", dto, {
          isUseResponse: true
        }).then(res => {
          if (res.data.code == 0) {
            for (var i = 0; i < res.data.data.length; i++) {
              var item = res.data.data[i];
              item.stateStr = ['成功','失败'][item.state - 1];
            }
          }
          opt.cb(res.data);
        });
      }
    }
  };
</script>